import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  Placement,
  Property,
  PropertyTheme,
} from '@sites/data-hmm/hmm-incubator';

export const propertyServiceActions = createActionGroup({
  source: 'DashboardStore PropertyService',
  events: {
    List: emptyProps(),
    ListRefresh: emptyProps(),
    ListSuccess: props<{
      properties: Property[];
      themes: PropertyTheme[];
      placements: Placement[];
    }>(),
    ListFailure: props<{ error: Error }>(),
  },
});
