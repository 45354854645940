import { createReducer, on } from '@ngrx/store';
import {
  Placement,
  Property,
  PropertyTheme,
} from '@sites/data-hmm/hmm-incubator';
import { produce } from 'immer';
import { propertyServiceActions } from './property.actions';

export const PropertyKey = 'property';

export interface PropertyState {
  properties: Property[];
  themes: PropertyTheme[];
  placements: Placement[];
  loading: boolean;
  error?: Error;
}

export const initialPropertyState: PropertyState = {
  properties: [],
  themes: [],
  placements: [],
  loading: false,
  error: undefined,
};

export const propertyReducer = createReducer(
  initialPropertyState,
  on(propertyServiceActions.listRefresh, (state) =>
    produce(state, (draft) => {
      draft.properties = [];
      draft.themes = [];
      draft.placements = [];
      draft.loading = true;
      draft.error = undefined;
    })
  ),
  on(
    propertyServiceActions.listSuccess,
    (state, { properties, themes, placements }) =>
      produce(state, (draft) => {
        draft.properties = properties;
        draft.themes = themes;
        draft.placements = placements;
        draft.loading = false;
        draft.error = undefined;
      })
  ),
  on(propertyServiceActions.listFailure, (state, { error }) =>
    produce(state, (draft) => {
      draft.properties = [];
      draft.themes = [];
      draft.placements = [];
      draft.loading = false;
      draft.error = error;
    })
  )
);
