import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Property_ID } from '@sites/data-hmm/hmm-incubator';
import { PropertyKey, PropertyState } from './property.reducer';

const deprecatedProperties = [
  Property_ID.ID_STONE_SMASH,
  Property_ID.ID_THE_DAILY_BUGLE,
];

const selectPropertyState = createFeatureSelector<PropertyState>(PropertyKey);

export const selectPropertyLoading = createSelector(
  selectPropertyState,
  (state) => state.loading
);

export const selectAllProperties = createSelector(
  selectPropertyState,
  (state) => state.properties
);

export const selectAllPropertyThemes = createSelector(
  selectPropertyState,
  (state) => state.themes
);

export const selectAllPlacements = createSelector(
  selectPropertyState,
  (state) => state.placements
);

export const selectFilteredProperties = createSelector(
  selectAllProperties,
  (state) => state.filter((x) => !deprecatedProperties.includes(x.id))
);
